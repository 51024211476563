.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: blue;
}

.App-link {
  color: #09d3ac;
}

.image {
  height: 100px
}

.nav-link {
  font-size: 1em;
  margin: 1em;
  color: lightgray;
}

p {
  font-size: 2em;
}

.titleText {
  color:white;
  font-weight: 400;
  font-size: 40px;
}

.subtitleText {
  color:white;
  font-weight: 300;
  font-size: 30px;
}

.text {
  color:white;
  font-weight: 100;
  font-size: 1.7em;
}

.overlayRow{
  margin: 20px
}

.col{
  align-items:center;
  align-content:center;
  text-align: center;
}
.row{
  margin-top: 10em;
  margin-bottom: 10em;
  align-items: center;
  padding: 20px;
  align-content: center;
}

.containerRow{
  background-size: cover;
  width: 100%;
  height: 500px
}

.sectionOne{
  justify-content: center;
  padding: 50px;
  height: 100%;
  width:40%;
  padding-top: 90px
}

.sectionTwo{
  justify-content: center;
  padding: 50px;
  height: 100%;
  width:100%;
  padding-top: 90px
}

img.lazyloaded{
  max-width:80%

}
@media(max-width:600px){
  .overlayRow{
    max-height: 900px;
    margin: 0;
    height: 900px;
  }
  img.lazyloaded{
    width:400px
  }
}